import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser';
const Join = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_dex7a0w', 'template_mixreot', form.current, 'ItQXVfytH7bjwZjsr')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
<div className="join">
    <div className="left-j">
    <hr />
        <div>
        <span className='stroke-text'>Ready to </span>
        <span> Level up</span>
        </div>
        <div>
            <span>Your body </span>
            <span className='stroke-text'> With Us?</span>
        </div>
    </div>
    <div className="right-j">
        <form ref={form} onSubmit={sendEmail} className="email-container">
            <input type="email" name="user_name" placeholder='Enter your email here' />
              <button className="btn btn-j">Join now</button>
        </form>
    </div>
</div>
  )
}

export default Join
