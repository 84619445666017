import React from 'react'
import './Reasons.css'
import  image1 from '../hero/Header/assest/image1.png'
import image2 from '../hero/Header/assest/image2.png'
import image3 from '../hero/Header/assest/image3.png'
import image4 from '../hero/Header/assest/image4.png'
import nb from '../hero/Header/assest/nb.png'
import adidas from '../hero/Header/assest/adidas.png'
import nike from '../hero/Header/assest/nike.png'
import tick from '../hero/Header/assest/tick.png'
const Reasons = () => {
  return (
   <div className="reasons">
    <div className="left-r">
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
    </div>
    <div className="right-r">
        <span>Some Reasons</span>
        <div>
            <span className='stroke-text'>Why </span>
            <span>Choose Us?</span>
            </div>
            <div className='details-r'>
                <div>
                      <img src={tick} alt="" />
                    <span>Over +140 Expert Coaches</span>
                  
                    </div>
                <div>
                      <img src={tick} alt="" />
                    <span>Train smarter and faster than before</span>
                    
                    </div>
                <div>
                      <img src={tick} alt="" />
                    <span>1 free program for new member</span>
                  
                    </div>
                <div>
                      <img src={tick} alt="" />
                    <span>Reliable partners</span>
                    </div>
            </div>
            <span style={{
                color:"var(--gray)",
                fontWeight:"normal"
            }}>Our Partners</span>
            <div className="partners">
              <img src={nb} alt="" />
              <img src={adidas} alt="" />
              <img src={nike} alt="" /></div>
    </div>
   </div>
  )
}

export default Reasons
