import React from 'react'
import github from '../componets/hero/Header/assest/github.png'
import instagram from '../componets/hero/Header/assest/instagram.png'
import linkedin from '../componets/hero/Header/assest/linkedin.png'
import logo from '../componets/hero/Header/assest/logo.png'
import './Footer.css'

const Footer = () => {
  return (
   <div className="footer-container">
    <hr />
    <div className="footer">
    <div className="social-links">
        <img src={github} alt="" />
        <img src={instagram} alt="" />
        <img src={linkedin} alt="" />
    </div>
    <div className='logo-f'>
        <img src={logo} alt="" />
    </div>
   </div>
   <div className='blur footer-blur-1'></div>
   <div className='blur footer-blur-2'></div>
   </div>
  )
}

export default Footer
