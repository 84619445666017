
 import React from 'react'
 import './Plans.css'
 import {plansData} from '../programs/data/plansData'
 import whiteTick from '../hero/Header/assest/whiteTick.png'
 const Plans = () => {
   return (
    <div className="plans-container">
        <div className='blur plan-blur-1'></div>
        <div className='blur plan-blur-2'></div>
        <div className="program-header">
            <span className='stroke-text'>Ready to Start</span>
            <span>Your JOurney</span>
            <span className='stroke-text'>Now with US</span>
        </div>
        <div className="plans">
            {plansData.map((plan)=>(
                <div className="plan">
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>$ {plan.price}</span>
                    <div className="features">
                        {plan.features.map((feature)=>(
                            <div className="feature">
                                <img src={whiteTick} alt="" />
                                <span>{feature}</span>
                            </div>
                            
                        ))}</div>
                        <div>
                            <span>See more benefits -></span>
                        </div>
                        <button className="btn">Join now</button>
                    
                </div>
            ))}
        </div>
    </div>
   )
 }
 
 export default Plans
 