import React from 'react'
import './hero.css'
import Calories from './Header/assest/calories.png'
import hero_img from './Header/assest/hero_image.png'
import hero_img_back from './Header/assest/hero_image_back.png'
import Heart from './Header/assest/heart.png'
import Header from './Header/Header'
import { motion } from 'framer-motion'
import NumberCounter from 'number-counter'

const hero = () => {
  const transitions = {type:'spring', duration : 3}
const mobile = window.innerWidth <= 768 ? true : false;
  return (
    <div className="hero">
      <div className='blur hero-blur'></div>
   <div className="left-h"><Header/>
   <div className='the-best-ad'>
    <motion.div
    initial  = {{left:mobile?'140px':'200px'}}
    whileInView = {{left:"8px"}}
    transition ={{ ...transitions, type:'tween'}}
    ></motion.div>
    <span>The best fitness club in the town</span>
   </div>
   <div className="hero-text">
    <div>
      <span className='stroke-text'>Shape </span>
      <span>Your</span>
    </div>
    <div>
      <span>Ideal Body</span>
    </div>
    <div>
      <span>In here we will help you to shape and build your 
        ideal body and live up your life to fullest.
      </span>
    </div>
   </div>
   <div className="figures">
    <div>
      <span>
        <NumberCounter end={140} start ={100} delay ='4' preFix='+'></NumberCounter>
      </span>
      <span>Expert Coaches</span>
    </div>
    <div>
      <span> <NumberCounter end={978} start ={800} delay ='4' preFix='+'></NumberCounter></span>
      <span>Members joined</span>
    </div>
    <div>
      <span> <NumberCounter end={50} start ={0} delay ='3' preFix='+'></NumberCounter></span>
      <span>Fiteness Programs</span>
    </div>
   </div>
   <div className="buttons">
    <button className="btn">Get Started</button>
    <button className="btn">Learn More</button>
   </div>
   </div>
   <div className="right-h">
  <button className="btn">Join now</button>
  <motion.div
  initial ={{right:"-1rem"}}
  whileInView ={{right :"4rem"}}
  transition ={transitions}
  className="heart-rate">
    <img src={Heart} alt="" />
      <span>Heart Rate</span>
      <span>116 bpm</span>
  </motion.div>
  <img src={hero_img} alt="" className='hero_img' />
  <motion.img
  initial={{right:'11rem'}}
  whileInView ={{right:'20rem'}}
  transition ={transitions} src={hero_img_back} alt="" className='hero_img_back' />
  <motion.div 
  initial={{right:"37rem"}}
  whileInView ={{right:"28rem"}}
  transition ={transitions} className="calories">
    <img src={Calories} alt="" />
    <div>
      <span>CaloriesBurned</span>
      <span>220 Kcal</span>
    </div>
  </motion.div>
   </div>
   </div>
  )
}

export default hero
