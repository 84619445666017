import React from 'react'
import {programsData} from './programsData';
import './program.css'
import  rightArrow from '../hero/Header/assest/rightArrow.png'
const Programs = () => {
  return (
   <div className="programs">
    <div className="program-header">
        <span className='stroke-text'>Explore Our</span>
        <span>Programs</span>
        <span className='stroke-text'>To Shape You</span>
    </div>
    <div className="program-categories">
        {programsData.map((program)=>
        (
            <div className="category">
               {program.image}
               <span>{program.heading}</span>
               <span>{program.details}</span>
               <div className="join-now">
                <span>Join Now</span>
                <img src={rightArrow} alt="" />
               </div>
            </div>
        ))}
    </div>
   </div>
  )
}

export default Programs
